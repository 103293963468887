<template>
    <div class="skill">
        <h4>{{skill.name}}</h4>
        <p v-html="$filters.formatText(skill.text)"></p>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import data from '../data/cv';

export default defineComponent({
    data(){
        return {
            skill: null
        };
    },
    beforeMount(){
        var key = this.$route.params.key;
        this.skill = data.skills.find(x => x.key == key);
    },
    watch: {
        "$route.params.key": function(){
            var key = this.$route.params.key;
            this.skill = data.skills.find(x => x.key == key);
        }
    }
})
</script>
<style>

</style>
