
import { defineComponent } from 'vue'
import data from '../data/cv'
export default defineComponent({
    data(){
        return {
            interests: data.personal.interests    
        };
    }
})
