import SkillList from '../components/SkillList.vue';
import Home from '../components/Home.vue';
import Skill from '../components/Skill.vue';
import Personal from '../components/Personal.vue';
import Interest from '../components/Interest.vue';
import Timeline from '../components/Timeline.vue';
import Printable from '../components/Printable.vue';

const routes = [{
    path: '/',
    component: Home
},
{
    path: '/skills',
    component: SkillList
},
{
    path: '/skills/:key',
    component: Skill
},{
    path: '/personal',
    component: Personal
},
{
    path: '/personal/:key',
    component: Interest
},{
    path: '/experience/:dateString?',
    component: Timeline
},{
    path: '/pdf',
    component: Printable
}];

export default routes;