<template>
    <div class="skill-list" v-if="!$style.is('material')">
        <fieldset>
            <legend>Languages</legend>
            <ul>
                <li v-for="skill in languages" 
                    v-html="$filters.skillLink(skill.key)"
                    :key="skill.key"></li>
            </ul>
        </fieldset>
        <fieldset>
            <legend>Frameworks</legend>
            <ul>
                <li v-for="skill in frameworks" 
                    v-html="$filters.skillLink(skill.key)"
                    :key="skill.key"></li>
            </ul>
        </fieldset>
        <fieldset>
            <legend>Libraries</legend>
            <ul>
                <li v-for="skill in libraries" 
                    v-html="$filters.skillLink(skill.key)"
                    :key="skill.key"></li>
            </ul>
        </fieldset>
        <fieldset>
            <legend>Infrastructure</legend>
            <ul>
                <li v-for="skill in infrastructure" 
                    v-html="$filters.skillLink(skill.key)"
                    :key="skill.key"></li>
            </ul>
        </fieldset>
    </div>
    <div class="skill-list" v-if="$style.is('material')">
        <div class="card">
            <div class="card-title"><p>Languages</p></div>
            <div class="card-content">
                <ul>
                <li v-for="skill in languages" 
                    v-html="$filters.skillLink(skill.key)"
                    :key="skill.key"></li>
            </ul>
            </div>
        </div>
        <div class="card">
            <div class="card-title"><p>Frameworks</p></div>
            <div class="card-content">
                <ul>
                <li v-for="skill in frameworks" 
                    v-html="$filters.skillLink(skill.key)"
                    :key="skill.key"></li>
            </ul>
            </div>
        </div>
        <div class="card">
            <div class="card-title"><p>Libraries</p></div>
            <div class="card-content">
                <ul>
                <li v-for="skill in libraries" 
                    v-html="$filters.skillLink(skill.key)"
                    :key="skill.key"></li>
            </ul>
            </div>
        </div>
        <div class="card">
            <div class="card-title"><p>Infrastructure</p></div>
            <div class="card-content">
                <ul>
                <li v-for="skill in infrastructure" 
                    v-html="$filters.skillLink(skill.key)"
                    :key="skill.key"></li>
            </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import data from '../data/cv';
import {SkillType} from '../types/skill';
export default defineComponent({
    data(){
        return {
            languages: [],
            frameworks: [],
            libraries: [],
            infrastructure: []
        };
    },
    beforeMount(){ 
        this.languages = data.skills.filter(x => x.type == SkillType.Language);
        this.frameworks = data.skills.filter(x => x.type == SkillType.Framework);
        this.libraries = data.skills.filter(x => x.type == SkillType.Library);
        this.infrastructure = data.skills.filter(x => x.type == SkillType.Infrastructure);
    }
})
</script>
<style lang="scss" scoped>
    .skill-list{
        display: flex;
        height: auto;
        flex-wrap: wrap;
        width: 100%;
    }
    .card{
        margin-left: 12px;
        margin-right: 12px;
    }
    fieldset, .card{
        width: calc(25% - 32px);
    }
    @media(max-width: 1024px){
        fieldset, .card{
            width: calc(50% - 32px);
        }
    }
    @media(max-width: 768px){
        fieldset, .card{
            width: calc(100% - 32px);
        }
    }
    
</style>
