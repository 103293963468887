import { Skill } from '../types/skill';
import cv from '../data/cv';
import dayjs from 'dayjs';

const skills: {[key: string]: Skill} = cv.skills.reduce((prev, cur) => Object.assign({}, prev, {[cur.key]: cur}), {});

function interestLink(link: any){
    const name = link.name;
    return '<a href="/#/personal/' + link.key + '">' + name + '</a>';
}
function skillLink(key: string){
    const name = skillName(key);
    if(!name){
        return;
    }
    return '<a href="/#/skills/' + key + '">' + name + '</a>';
}
function skillName(key: string){
    const skill = skills[key];
    if(!skill){
        console.log("Skill not found: " + key);
        return;
    }
    return skill.name;
}
function formatDate(date: Date, format: string){
    if(!format){format = 'DD/MM/YYYY';}
    return dayjs(date).format(format);
}

function formatText(text: string){
    // text can contain links to skills, denoted by %key%
    const replaceRegex = /%([a-z.]+)%/g;
    return text.replace(replaceRegex, (str: string, ...matches: string[]) => {
        return <string>skillLink(matches[0]);
    });
}

export default {
    skillLink,
    skillName,
    formatDate,
    formatText,
    interestLink
}