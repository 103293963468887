
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import data from '../data/cv';

export default defineComponent({
    name: "education",
    props: {
        education: Object
    },
})
