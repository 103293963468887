
import { defineComponent } from 'vue';
import data from '../data/cv';
export default defineComponent({
    data(){
        return {
            ...data
        };
    },
    methods: {
        formatText(str: string){
            const replaceRegex = /%([a-z.]+)%/g;
            return str.replace(replaceRegex, (str: string, ...matches: string[]) => {
                return (this as any).$filters.skillName(matches[0])as string;
            });
        },
        formatSkills(skills: string[]){
            return skills.map(x => (this as any).$filters.skillName(x)).join(", ");
        },
        print(){
            window.print();
        }
    }
})
