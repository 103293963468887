<template>
    <div>
        <h1>{{home.header}}</h1>
        <p>{{home.body}}</p>
        <fieldset>
            <ul class="spaced">
                <li><router-link to="/experience">Timeline</router-link> - my educational and professional history.</li>
                <li><router-link to="/skills">Skills</router-link> - the skills I've learned and used over my career.</li>
                <li><router-link to="/personal">Personal</router-link> - my personal interests.</li>
                <li><router-link to="/pdf">PDF</router-link> - A version of my CV which can be printed, or turned into a PDF. The text can be easily copied.</li>
            </ul>
        </fieldset>
    </div>
</template>
<script>
import { defineComponent } from 'vue'
import data from '../data/cv';
const home = data.home;
export default defineComponent({
    data(){
        return {
            home
        };
    }
})
</script>
<style>

</style>
