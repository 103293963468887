
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import {Experience} from '../types/experience';
import data from '../data/cv';

const experience = data.experience;

export default defineComponent({
    name: "experience",
    props: {
        experience: Object
    },
})
