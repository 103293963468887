
import { Interest } from '@/types/personal';
import { defineComponent } from 'vue'
import data from '../data/cv'

const interests = data.personal.interests;
export default defineComponent({
    data(){
        return {
            interest: {} as Interest
        };
    },
    beforeMount(){
        this.interest = interests.find(z => z.key == this.$route.params.key) as Interest;
    }
})
