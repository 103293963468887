
import { defineComponent, Ref } from 'vue';
import ExperienceComponent from './Experience.vue';
import EducationComponent from './Education.vue';
import {Experience} from '../types/experience';
import {Skill} from '../types/skill';
import _ from 'lodash';
import data from '../data/cv';
import dayjs, { Dayjs } from 'dayjs';
const experience: Experience[] = data.experience;
const education: {startDate: Dayjs, endDate: Dayjs}[] = data.education as any;
let timelineData: {startDate: Dayjs, endDate: Dayjs}[]= [...experience, ...education] as any[];
timelineData = _.orderBy(timelineData, (x:any) => x.startDate.valueOf(), 'desc');
console.log(timelineData);
function getItem(date: any): {startDate: Dayjs, endDate: Dayjs}|null{
    if(!date.isBetween){
        date = dayjs(date);
    }
    var possibleMatches = timelineData
        .filter(x => date.isBetween(x.startDate, x.endDate, 'day', '[]'));
    if(possibleMatches.length == 1){
        return possibleMatches[0];
    }
    if(possibleMatches.length == 2){
        return possibleMatches.find(x => (x as any).secondaryCompany) as {startDate: Dayjs, endDate: Dayjs};
    }
    return null;
}
export default defineComponent({
    name: "timeline",
    components: {
        Experience: ExperienceComponent,
        Education: EducationComponent
    },
    data(){
        return {
            timelineData,
            currentExperience: null as any,
            currentEducation: null as any,
            currentDate: null as any,
            dateRangeMax: 0,
            startDate: null as any,
            rangeInputValue: 0,
            optionRefs: [] as Element[]
        };
    },
    beforeMount(){
        const startDate: dayjs.Dayjs = _.minBy(
            timelineData.map(x => x.startDate), 
            (y: dayjs.Dayjs) => y.valueOf());
        this.startDate = startDate;
        const maxDate: dayjs.Dayjs = dayjs();/*_.maxBy(
            experience.map(x => x.endDate),
            (y: dayjs.Dayjs) => y.valueOf());*/
        const dateDifference = maxDate.diff(startDate, 'day');
        this.dateRangeMax = Math.abs(dateDifference);
        const storedDate = window.localStorage.getItem("experience-date");
        if(storedDate){
            this.currentDate = dayjs(storedDate as string);
            this.rangeInputValue = Math.abs(startDate.diff(this.currentDate, 'day'));
        } else {
            this.currentDate = maxDate;
            this.rangeInputValue = this.dateRangeMax;
        }
        const item:any = getItem(this.currentDate);
        if(!item){
            this.currentExperience = null;
            this.currentEducation = null;
        } else if(item.mainCompany){
            this.currentExperience = item;
        } else {
            this.currentEducation = item;
        }
        
        
    },
    methods:{
        setItemRef(el: Element){
            setTimeout(() => {
                const width = el.clientWidth;
                const existingStyle: string = el.getAttribute("style") || "";
                const newStyle = (existingStyle.indexOf("margin-left") > -1 
                    ? existingStyle.split("margin-left")[0]
                    : existingStyle) + " margin-left: -" + (width/2).toFixed(1) + "px;";
                el.setAttribute("style", newStyle);
                this.optionRefs.push(el);
            }, 0);
        },
        dateRangeInput(e: any): void{
            const val = +e.target.value;
            this.currentDate = (this.startDate as dayjs.Dayjs).add(val, 'day');
            const item:any = getItem(this.currentDate);
            if(!item){
                this.currentExperience = null;
                this.currentEducation = null;
            } else if(item.mainCompany){
                this.currentExperience = item;
                this.currentEducation = null;
            } else {
                this.currentEducation = item;
                this.currentExperience = null;
            }
            this.rangeInputValue = val;
            window.localStorage.setItem("experience-date", this.currentDate.format("YYYY-MM-DD"))
        },
        getDaysValue(date: Dayjs){
            return Math.abs(this.startDate.diff(date, 'day'))
        },
        getOptionPosition(exp: any){
            const days = Math.abs(this.startDate.diff(exp.startDate, 'day'));
            const timeOffset = ((days / this.dateRangeMax)*100).toFixed(2) + "%";
            return timeOffset;
        },
        getOptionStyle(item: any){
            return {
                left: this.getOptionPosition(item)
            };
        },
        tickLabel(item: any){
            if(item.mainCompany){
                return item.mainCompany + (item.secondaryCompany ? (' (' + item.secondaryCompany + ')') : '')
            } else {
                return item.institution;
            }
        }
    },
    watch:{
        $route(){
            const storedDate = window.localStorage.getItem("experience-date");
            if(storedDate){
                this.currentDate = dayjs(storedDate as string);
            }
        }
    }
})
