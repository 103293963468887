import { Experience } from "../types/experience"
import { Skill, SkillType } from "../types/skill"
import _ from 'lodash';
import { Personal } from "@/types/personal";
import {experience, education} from '../data/experience.json';
import {skills} from '../data/skills.json';
import {interests, home, details} from '../data/personal.json';
import dayjs, { Dayjs } from "dayjs";
export type Config = {
    education: {
        startDate: Dayjs;
        endDate: Dayjs;
        subject: string;
        level: string;
        achieved: string;
        text: string;
    }[],
    experience: Experience[],
    skills: Skill[],
    personal: Personal;
    home: any;
    details: any;
}

const config: Config ={
    home,
    education: education.map(x => {
        return Object.assign({}, x, 
            {
                startDate: dayjs(x.startDate),
                endDate: x.endDate ? dayjs(x.endDate) : undefined
            });
    }),
    details,
    experience: experience.map(x => {
        return Object.assign({}, x, 
            {
                startDate: dayjs(x.startDate),
                endDate: x.endDate ? dayjs(x.endDate) : undefined
            });
    }),
    skills: skills.map(x => {
        return Object.assign({}, x, {
            type: (<any>SkillType)[x.type]
        });
    }),
    personal: {
        interests
    }
};
config.experience = _.orderBy(config.experience, (x:any) => x.startDate, 'desc');
config.education = _.orderBy(config.education, (x:any) => x.startDate, 'desc');
export default config;