import { createApp, ref } from "vue";
import App from "./App.vue";
import filters from './filters/filters';
import {createRouter, createWebHashHistory} from 'vue-router';
import routes from './routes/routes';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';


dayjs.extend(isBetween)
const app = createApp(App);

const router = createRouter({
    history: createWebHashHistory(),
    routes
});
app.use(router);
app.config.globalProperties.$filters = {
    ...filters
}

const style = window.localStorage.getItem("style") || 'contrast';
const styleRef = ref(style);
const styleObj = {
    ref: styleRef,
    is(s: string){return styleRef.value == s;},
    set(s: string){window.localStorage.setItem("style", s); styleRef.value = s;}
};
app.config.globalProperties.$style = styleObj; 

app.mount("#app");
