import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40cc398a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "education" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.education.level) + " " + _toDisplayString(_ctx.education.achieved) + " " + _toDisplayString(_ctx.education.subject), 1),
    _createElementVNode("h4", null, _toDisplayString(_ctx.education.institution), 1),
    _createElementVNode("h5", null, [
      _createElementVNode("small", null, _toDisplayString(_ctx.$filters.formatDate(_ctx.education.startDate, "MMMM 'YY")) + " - " + _toDisplayString(_ctx.education.endDate ? _ctx.$filters.formatDate(_ctx.education.endDate, "MMMM 'YY") : 'Current'), 1)
    ]),
    _createElementVNode("p", {
      innerHTML: _ctx.$filters.formatText(_ctx.education.text)
    }, null, 8, _hoisted_2)
  ]))
}